import axios from 'axios';
import { ICompany } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));


export const getCompanies = async () => {
    const res = await axios<ICompany[]>({
        url: `${API}/companies/`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const getCompany = async (id: number) => {
    const res = await axios<ICompany>({
        url: `${API}/companies/${id}/`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 
