import React from 'react';
import { Container } from './styles';
import { Card } from '@/shared'; 
import { EmptyResponse } from '../EmptyResponse/EmptyResponse';
import { CourseCard } from '@/entities/course';    

export interface ICoursesListProps {
  courses: any[];
}

export const CoursesList: React.FC<ICoursesListProps> = ({ courses }) => {
  return (
    <Container>
      {courses.length === 0 && <EmptyResponse/>}
      {courses.map(course =>
        <Card key={course.id}>
          <CourseCard course={course} key={course.id}/>
        </Card>
      )}
    </Container>
  )
}
