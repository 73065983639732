import React from 'react';
import {
  Page,
  Left,
} from './styles';
import { Profile } from '@/widgets';
import { MySkills } from '@/widgets';
import { Education } from '@/widgets';
import { CareerWidget } from '@/widgets';

export const EmployeeProfile: React.FC = () => {
  return (
    <Page>
      <Left>
        <CareerWidget/>
        <MySkills/>
        <Education/>
      </Left>
      <Profile/>
    </Page>
  )
}
