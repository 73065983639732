import React, { useEffect } from 'react';
import { CoursesTopbar, CoursesList } from '@/widgets';
import { getCourses } from '@/features/employee';
import { observer } from 'mobx-react-lite';
import coursesState from '@/entities/course/store/coursesState';

export const Courses: React.FC = observer(() => {
  useEffect(() => {
    getCourses()
      .then((res: any) => {
        if (res?.status === 200) {
          coursesState.setCourses(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  return (
    <>
      <CoursesTopbar/>
      <CoursesList courses={coursesState.courses}/>
    </>
  )
});
