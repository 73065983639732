import React from 'react';
import {createBrowserRouter} from "react-router-dom";
import {
    AuthLayout,
    AdminLayout,
    HrLayout,
    EmployeeLayout,
    ManagerLayout,
} from "../layouts";
import { SignIn } from "@/pages/SignIn";
import { RecoveryPassword } from "@/pages/RecoveryPassword";
import { ResetPassword } from "@/pages/ResetPassword";
import { Admin } from "@/pages/Admin";
import { Vacancies } from "@/pages/Vacancies";
import { Employees } from "@/pages/Employees";
import { Structure } from "@/pages/Structure";
import { EmployeeProfile } from "@/pages/EmployeeProfile";
import { Page404 } from "@/pages/404";
import { Courses } from "@/pages/Courses";
import { Career } from "@/pages/Career";

export const router = createBrowserRouter([
    {
        element: <AuthLayout />,
        children: [
            {
                path: '/',
                element: <SignIn/>
            },
            {
                path: '/RecoveryPassword',
                element: <RecoveryPassword/>
            },
            {
                path: '/ResetPassword',
                element: <ResetPassword/>
            },
        ]
    },
    {
        element: <AdminLayout />,
        children: [
            {
                path: '/dashboard-admin-companies',
                element: <Admin/>,
            },
            {
                path: '/dashboard-admin-settings',
                element: <Page404/>,
            },
        ],
    },
    {
        element: <HrLayout />,
        children: [
            {
                path: '/dashboard-hr-vacancies',
                element: <Vacancies/>,
            },
            {
                path: '/dashboard-hr-employees',
                element: <Employees/>,
            },
            {
                path: '/dashboard-hr-structure',
                element: <Structure/>,
            },
            {
                path: '/dashboard-hr-tests',
                element: <Page404/>,
            },
            {
                path: '/dashboard-hr-analitic',
                element: <Page404/>,
            },
            {
                path: '/dashboard-hr-projects',
                element: <Page404/>,
            },
        ],
    },
    {
        element: <EmployeeLayout />,
        children: [
            {
                path: '/dashboard-employee-profile',
                element: <EmployeeProfile/>,
            },
            {
                path: '/dashboard-employee-tasks',
                element: <Page404/>,
            },
            {
                path: '/dashboard-employee-career',
                element: <Career/>,
            },
            {
                path: '/dashboard-employee-courses',
                element: <Courses/>,
            },
            {
                path: '/dashboard-employee-tests',
                element: <Page404/>,
            },
            {
                path: '/dashboard-employee-projects',
                element: <Page404/>,
            },
        ],
    },
    {
        element: <ManagerLayout />,
        children: [
            {
                path: '/dashboard-manager-profile',
                element: <EmployeeProfile/>,
            },
            {
                path: '/dashboard-manager-tasks',
                element: <Page404/>,
            },
            {
                path: '/dashboard-manager-career',
                element: <Career/>,
            },
            {
                path: '/dashboard-manager-courses',
                element: <Courses/>,
            },
            {
                path: '/dashboard-manager-tests',
                element: <Page404/>,
            },
            {
                path: '/dashboard-manager-projects',
                element: <Page404/>,
            },
            {
                path: '/dashboard-manager-employees',
                element: <Employees/>,
            },
        ],
    },
]);
