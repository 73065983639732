import React, { useState } from 'react';
import styles from './styles.module.scss';

export type TOption = {
  id: number;
  name: string;
  onClick: () => void;
}

export interface IOptions {
  options: TOption[];
}

export const Filters: React.FC<IOptions> = ({ options }) => {
  const [active, setActive] = useState(options[0].name);

  function handleSetFilter(option: TOption) {
    setActive(option.name);
    option.onClick();
  }

  return (
    <div className={styles.container}>
      {options.map(option =>
        <button
          key={option.id}
          onClick={() => handleSetFilter(option)}
          className={
            active === option.name ? 
              styles.button + ' ' + styles.active :
              styles.button
          }
        >
          <h6>{option.name}</h6>
        </button>
      )}
    </div>
  )
}
