import styled from 'styled-components';

export const Page = styled.div`
    display: flex;    
`;

export const Board = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: calc(100vw - 224px);
    min-width: 755px;
    padding: 40px 40px 0 20px;
    max-height: 100vh;
    overflow: auto;
`;