import styled from "styled-components";

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Bottom = styled.div`
  
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const H6 = styled.h6`
    color: var(--main-purple);
`;
