import axios from 'axios';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const getSteps = async () => {
  const res = await axios<any>({
    url: `${API}/specializations/by_vacancy_first/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
} 

export const savePromt = async (step: any) => {
  const res = await axios<any>({
    url: `${API}/specializations/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
    data: step
  })
    
  return res
} 
