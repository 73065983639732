import React, { useState, useEffect } from 'react';
import { 
    Container,
    Image,
    Info,
    Section,
} from './styles';
import { Card } from '@/shared';
import avatar from '@/assets/images/avatar.jpg';
import { SkillsList } from '@/widgets';
import { getCompany } from '@/entities/company/api';
import { getGroupById } from '@/features/department';
import { ICompany } from '@/shared';
import companyState from '@/entities/company/store/companyState';
import { observer } from 'mobx-react-lite';

export const Profile: React.FC = observer(() => {
  const [group, setGroup] = useState<any>();
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    getCompany(user?.company[0])
        .then((res: any) => {
            if (res?.status === 200) {
                companyState.setCompany(res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    
    getGroupById(user?.group[0])
        .then((res: any) => {
            if (res?.status === 200) {
                setGroup(res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        })
  }, [])

  return (
    <Container>
        <Image src={user?.image ? user.image : avatar}/>
        <Card>
            <Info>
                <div><h5>{user?.first_name} {user?.last_name} {user?.patronymic},</h5> {user?.age ? <>{user?.age}{' '}</> : <></>}</div>
                {user?.specialization && 
                    <div>
                        <h5>{user.specialization?.grade} {user.specialization?.title}</h5>
                        <>{group?.name && <>отдел {group.name}</>} {companyState.company?.name && <>«{companyState.company.name}»</>}</>
                    </div>
                }
            </Info>
            {/* <Place>
                <>работает</><h5>в г.Москва</h5>
            </Place> */}
            <Section>
                <h5>Интересы</h5>
                <SkillsList skills={user?.interests}/>
            </Section>
            {/* <Section>
                <h5>Резюме</h5>
                <Resume>
                    <File>
                        <ResumeIcon
                            width={24}
                            height={24}
                        />
                        <p>Vasneva.pdf</p>
                    </File>
                    <Popover placement='bottom-end'>
                        <PopoverTrigger>
                            <button>
                                <DotsIcon
                                    color='var(--main-purple)'
                                    width={20}
                                    height={20}
                                />
                            </button>
                        </PopoverTrigger>
                        <PopoverContent width={'114px'}>
                            <PopoverBody>
                                <PopoverButton>Скачать файл</PopoverButton>
                                <PopoverButton>Заменить файл</PopoverButton>
                                <PopoverButton
                                    style={{ color: 'var(--addable-red)' }}
                                >
                                    Удалить
                                </PopoverButton>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </Resume>
            </Section> */}
        </Card>
    </Container>
  )
});
