import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    min-width: 224px;
    height: 100vh;
    padding: 20px 8px;
    background-color: var(--main-lightgray);
`;

export const User = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Image = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 50%;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const P2 = styled.p`
    font-size: 12px;

    color: var(--disabled);
`;

export const Categories = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    height: 100%; 
`;

export const Category = styled.div``;

export const CategoryTitle = styled.h6`
    color: var(--disabled);
    margin-bottom: 4px;
`;

export const LinkWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    padding: 0 8px;
    border-radius: 6px;
    transition: all .2s ease-in-out;

    &:hover {
        background: var(--addable-gray);
    }
`; 

export const LogoutButton = styled.button`
    display: flex;
    width: 64px;
    justify-content: space-between;
    align-items: center;
`;
