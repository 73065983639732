import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 16px;
  background: var(--main-purple);
  width: 100%;
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h3`
  color: var(--main-white);
`;

export const ButtonText = styled.h6`
  color: var(--main-purple);
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Procent = styled.p`
  color: var(--main-white);
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Left = styled.div`
  width: fit-content;
  max-width: 616px;
  display: flex;
  gap: 8px;
`;

export const P = styled.p`
  font-size: 12px;
  color: var(--main-white);
`;
