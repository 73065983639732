import React from 'react';
import { HrCard } from '../../entities/user/ui/HrCard/HrCard';
import {
  Container,
} from './styles';
import { IUser, ICompany } from '@/shared';

export interface EmployeesListProps {
  users: IUser[];
  company: ICompany;
}

export const EmployeesList: React.FC<EmployeesListProps> = ({ users, company }) => {
  const filteredUsers = users.filter((user) => {
    return user.role !== 'Admin'
  })

  return (
    <Container>
        {filteredUsers && filteredUsers.map(user =>
          <HrCard company={company} user={user} key={user.id}/>
        )}
    </Container>
  )
}
