import React, { useState, useEffect } from 'react';
import {
  InputGroup,
  InputLeftElement,
  Input,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react';
import SearchIcon from '@/assets/icons/search.svg';
import {
  Container,
  Buttons,
  SearchButton,
  CourseButton,
  PopoverButton,
} from './styles';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { useDebounce } from '@/shared';
import { searchCourses } from '@/features/employee';
import coursesState from '@/entities/course/store/coursesState';

export const CoursesTopbar: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  function handleSearch() {
    searchCourses(debouncedSearchTerm)
    .then((res: any) => {
      if (res?.status === 200) {
        coursesState.setCourses(res.data);
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (typeof debouncedSearchTerm === 'string') {
      handleSearch();
    }
  }, [debouncedSearchTerm]);


  return (
    <Container>
        <InputGroup padding={'6px'}>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon
              color='var(--addable-gray)'
              width={18}
              height={18}
            />
          </InputLeftElement>
          <Input
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            color='messenger'
            height={'28px'}
            variant='flushed'
            placeholder='Поиск среди курсов'
          />
        </InputGroup>
        {/* <Buttons>
          <Popover placement='bottom-end'>
            <PopoverTrigger>
              <CourseButton>
                Stepic
                <CollapseIcon
                  width={14}
                  height={14}
                  color='var(--main-purple)'
                />
              </CourseButton>
            </PopoverTrigger>
            <PopoverContent width={'121px'}>
              <PopoverBody>
                <PopoverButton>Войти</PopoverButton>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <Popover placement='bottom-end'>
            <PopoverTrigger>
              <CourseButton>
                iSpring
                <CollapseIcon
                  width={14}
                  height={14}
                  color='var(--main-purple)'
                />
              </CourseButton>
            </PopoverTrigger>
            <PopoverContent width={'114px'}>
              <PopoverBody>
                <PopoverButton>Войти</PopoverButton>
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <Popover placement='bottom-end'>
            <PopoverTrigger>
              <SearchButton>
                Найти курс
                <CollapseIcon
                  width={14}
                  height={14}
                  color='var(--main-white)'
                />
              </SearchButton>
            </PopoverTrigger>
            <PopoverContent width={'121px'}>
              <PopoverBody>
                <PopoverButton>Stepic</PopoverButton>
                <PopoverButton>ISpring</PopoverButton>
              </PopoverBody>
            </PopoverContent>
          </Popover>

        </Buttons> */}
    </Container>
  )
}
