import axios from 'axios';
import { IUser } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));
const userId = JSON.parse(localStorage.getItem('user'))?.id;

export const getHrEmployees = async (companyName: string) => {
    const res = await axios({
        url: `${API}/users/?role__in=Super HR manager,HR manager&company__name=${companyName}` ,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const getEmployees = async (companyId: number) => {
    const res = await axios({
        url: `${API}/users/?company__id=${companyId}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const addEmployee = async (user: IUser) => {
    const res = await axios({
        url: `${API}/users/`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: user,
    })
    
    return res
} 

export const editEmployee = async (user: IUser, id: number) => {
    const updatedUser = {
        first_name: user.first_name,
        last_name: user.last_name,
        patronymic: user.patronymic,
        role: user.role,
        group: user.group,
    }

    const res = await axios({
        url: `${API}/users/${id}/`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: updatedUser,
    })
    
    return res
} 

export const editSkills = async (skills: any, id: number) => {
    const res = await axios({
        url: `${API}/users/${id}/`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: {
            skill: skills
        },
    })
    
    return res
} 

export const getSkills = async (id: number) => {
    const res = await axios<any[]>({
        url: `${API}/users/${id}/`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const getCourses = async () => {
    const res = await axios<any[]>({
        url: `${API}/courses/`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const searchCourses = async (term: string) => {
    const res = await axios<any[]>({
        url: `${API}/courses/?name=${term}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 


export const deleteEmployee = async (id: number) => {
    const res = await axios({
        url: `${API}/users/${id}/`,
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const searchEmployees = async (term: string) => {
    const res = await axios({
        url: `${API}/users/?search=${term}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const searchSkills = async (term: string, type: string) => {
    const res = await axios({
        url: `${API}/skills/?search=${term}&type=${type}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const generateCareer = async () => {
    const res = await axios({
        url: `${API}/specializations/`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 
