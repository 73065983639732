import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from './styles';

export interface BackButtonProps extends React.PropsWithChildren {}

export const BackButton: React.FC<BackButtonProps> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(-1)}>{children}</Button>
  )
}
