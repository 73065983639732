import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
`;

export const More = styled.button`
    font-size: 12px;
    font-weight: 600;
    color: var(--main-purple);
`;
