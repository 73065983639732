import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    min-height: 270px;
`;

export const Top = styled.div`
    display: flex;
    height: 24px;
    align-items: center;
    justify-content: space-between;
`;

export const Description = styled.div`
    height: fit-content;
    max-height: 64px;
    overflow: hidden;
`;

export const Text = styled.p`
    font-size: 12px;
    line-height: 16px;
`;

export const CollapseButton = styled.button`
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--main-purple);
    font-size: 12px;
    font-weight: 600;
`;

export const SkillsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const SkillsTitle = styled.h5`
    font-size: 12px;
    font-weight: 600;
`;
