import { makeAutoObservable } from "mobx";

class CareerState {
  steps: any[] = [];
  skills: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSteps(steps: any[]) {
    this.steps = steps;
  }

  setSkills(skills: any[]) {
    this.skills = skills;
  }
}

export default new CareerState();
