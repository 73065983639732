import React, { useState, useEffect } from 'react';
import {
  Tr,
  Td,
  Progress,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react';
import {
  Image,
  Text,
  PopoverButton,
  Manager,
} from './styles';
import { IUser } from '@/shared';
import avatar from '@/assets/images/avatar.jpg';
import DotsIcon from '@/assets/icons/dots.svg';
import { getGroupById } from '@/features/department';

export interface RowProps {
  employee: IUser;
  handleEdit: (employee: IUser) => void;
  handleDelete: (employee: IUser) => void;
}

export const Row: React.FC<RowProps> = ({ employee, handleEdit, handleDelete }) => {
  const [departName, setDepartName] = useState();
  const [groupName, setGroupName] = useState();
  const groupId = employee.group[employee.group.length - 1];

  console.log(employee);

  useEffect(() => {
    if (groupId) {
      getGroupById(groupId)
        .then((res) => {
          setGroupName(res.data.name);

          getGroupById(res.data.parent)
            .then((res) => {
              setDepartName(res.data.name);
            })
            .catch((e) => {
              console.log(e);
            })
        })
        .catch((e) => {
          console.log(e);
        })
    }
  
  }, [])

  return (
    <Tr key={employee?.id}>
      <Td
          display={'flex'}
          alignItems={'center'}
          gap='4px'
      >
          <Image src={avatar} alt="avatar" />
          <h6>{employee?.last_name} {' '} {employee?.first_name} {' '} {employee?.patronymic}</h6>
      </Td>
      <Td>
          {employee?.age ? <Text>{employee.age}</Text> : <Text>-</Text>}
      </Td>
      <Td>
          <Text>{employee?.vacancy[0]?.title ? employee?.vacancy[0]?.title : <>-</>}</Text>
      </Td>
      <Td>
          <Text>{employee.role === 'Manager' ? <Manager>Руководитель</Manager> : <>-</>}</Text>
      </Td>
      <Td>
          {employee?.specialization ? <Text>{employee.specialization?.title}</Text> : <Text>-</Text> }
      </Td>
      {/* <Td display={'flex'} alignItems={'center'} gap={'6px'}>
          <Text>23%</Text>
          <Progress
              background='var(--addable-gray)'
              borderRadius={'100px'}
              color='var(--main-purple)'
              value={23}
              height='4px'
              width={'60px'}
          />
      </Td> */}
      <Td>
        <Text>{groupName ? groupName : <>-</>}</Text>
      </Td>
      <Td>
          <Text>{departName ? departName : <>-</>}</Text>
      </Td>
      <Td>
          <div>
          <Popover placement='bottom-end'>
              <PopoverTrigger>
                  <button><DotsIcon color='var(--main-purple)'/></button>
              </PopoverTrigger>
              <PopoverContent width={'222px'} height={'72px'}>
                  <PopoverBody>
                      <PopoverButton
                          onClick={() => handleEdit(employee)}
                      >
                          Редактировать профиль
                      </PopoverButton>
                      {/* <PopoverButton>Перейти на страницу сотрудника</PopoverButton> */}
                      <PopoverButton
                          style={{ color: 'var(--addable-red)' }}
                          onClick={() => handleDelete(employee)}
                      >
                          Удалить сотрудника
                      </PopoverButton>
                  </PopoverBody>
              </PopoverContent>
          </Popover>
          </div>
      </Td>
    </Tr>
  )
}
