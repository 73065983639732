import axios from 'axios';
import { IVacanci } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const searchVacanci = async (term: string) => {
  const res = await axios<IVacanci[]>({
      url: `${API}/vacancies/?search=${term}&limit=50`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
} 