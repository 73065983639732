import { makeAutoObservable } from "mobx";
import { ICompany } from "@/shared";

class CompanyState {
  company: ICompany;

  constructor() {
    makeAutoObservable(this);
  }

  setCompany(company: ICompany) {
    this.company = company;
  }
}

export default new CompanyState();