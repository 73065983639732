import React from 'react';
import {RouterProvider} from "react-router-dom";
import { router } from './providers';
import { ChakraProvider } from '@chakra-ui/react';
import  './styles/index.scss';

export const App: React.FC = () => {
    return (
        <ChakraProvider>
            <RouterProvider router={router} />
        </ChakraProvider>
    );
};
