import React from 'react';
import { Container } from './styles';

export interface ICardProps extends Omit<React.ComponentProps<'div'>, 'ref'> {}

export const Card: React.FC<ICardProps> = ({ children }) => {
  return (
    <Container>{children}</Container>
  )
}
