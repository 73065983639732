import React from 'react';
import { Top, Inputs, Bottom } from '@/app/layouts/Auth/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
} from '@chakra-ui/react';
import { ButtonSolid } from '@/UI';

interface IFormFileds {
  password1: string;
  password2: string;
}

export const ResetPassword: React.FC = () => {
  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    console.log(data);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Top><h3>Восстановление пароля</h3></Top>
      <Inputs>
        <FormControl isInvalid={Boolean(errors.password1)}>
          <FormLabel htmlFor='password1'>Новый пароль *</FormLabel>
          <Input
            id='password1'
            {...register('password1', { 
              required: 'Обязательное поле'
            })}
            placeholder='••••••••'
            type='password'
            size='sm'
            variant='flushed'
          />
          <FormErrorMessage>{errors.password1 && <>{errors.password1.message}</>}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.password2)}>
          <FormLabel htmlFor='password2'>Повторите новый пароль *</FormLabel>
          <Input
            id='password2'
            {...register('password2', { 
              required: 'Обязательное поле'
            })}
            placeholder='••••••••'
            type='password'
            size='sm'
            variant='flushed'
          />
          <FormErrorMessage>{errors.password2 && <>{errors.password2.message}</>}</FormErrorMessage>
        </FormControl>
      </Inputs>
      <Bottom>
        <ButtonSolid
          type='submit'
          disabled={isSubmitting ? true : false}
        >
          Обновить пароль
        </ButtonSolid>
      </Bottom>
    </form>
  )
}
