import React, { useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    useDisclosure,
} from '@chakra-ui/react';
import type { IUser } from '@/shared';
import { DeleteUserPopup } from '@/entities/user/ui/DeleteUserPopup/DeleteUserPopup';
import { EditUserPopup } from '@/entities/user/ui/EditUserPopup/EditUserPopup';
import { companies } from '@/data/companies';
import { Row } from './Row';

export interface EmployeesTableProps {
    employees: IUser[];
}

export const EmployeesTable: React.FC<EmployeesTableProps> = ({ employees }) => {
  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const [currentUser, setCurrentUser] = useState<IUser>(employees[0]);

  useEffect(() => {
    setCurrentUser(employees[0]);
  }, [])

  function handleEdit(employee: IUser) {
    setCurrentUser(employee);
    onEditOpen();
  }

  function handleDelete(employee: IUser) {
    setCurrentUser(employee);
    onDeleteOpen();
  }

  return (
    <>
        <TableContainer>
            <Table size='sm'>
                <Thead>
                    <Tr>
                        <Th>ФИО</Th>
                        <Th>Возраст</Th>
                        <Th>Должность</Th>
                        <Th>Полномочия</Th>
                        <Th>Развитие карьеры</Th>
                        {/* <Th>Прогресс</Th> */}
                        <Th>Отдел</Th>
                        <Th>Департамент</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {employees && employees.map(employee =>
                        <Row handleEdit={handleEdit} handleDelete={handleDelete} employee={employee} key={employee.id}/>              
                    )}
                </Tbody>
            </Table>
        </TableContainer>
        <EditUserPopup
            company={companies[0]}
            user={currentUser}
            isOpen={isEditOpen}
            onClose={onEditClose}
         />
        <DeleteUserPopup userId={currentUser?.id} isOpen={isDeleteOpen} onClose={onDeleteClose}/>
    </>
  )
}
