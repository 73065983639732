import React, { useEffect, useState } from 'react';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Select
} from '@chakra-ui/react';
import { searchSkills } from '@/features/employee';
import { useDebounce } from '@/shared';
import SearchIcon from '@/assets/icons/search.svg';
import { Skill } from '@/shared';
import styled from 'styled-components';
import { editSkills, getSkills } from '@/features/employee';

export const SkillList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const SkillButton = styled.button`
  font-size: 18px;
  color: var(--main-white);
`;

export interface EditSkillsPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EditSkillsPopup: React.FC<EditSkillsPopupProps> = ({ isOpen, onClose }) => {
  const user = JSON.parse(localStorage.getItem('user'));

  const [skills, setSkills] = useState([]);

  const [softSkills, setSoftSkills] = useState([]);
  const [hardSkills, setHardSkills] = useState([]);
  const [searchSoftTerm, setSearchSoftTerm] = useState('');
  const [searchHardTerm, setSearchHardTerm] = useState('');

  const debouncedSearchSoftTerm = useDebounce(searchSoftTerm, 500);
  const debouncedSearchHardTerm = useDebounce(searchHardTerm, 500);

  useEffect(() => {
    getSkills(user?.id)
      .then((res: any) => {
        if (res?.status === 200) {
          setSkills(res.data.skill);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  function handleSoftSearch(type: string) {
    searchSkills(debouncedSearchSoftTerm, type)
    .then((res: any) => {
      if (res?.status === 200) {
        setSoftSkills(res.data);
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }

  function handleHardSearch(type: string) {
    searchSkills(debouncedSearchHardTerm, type)
    .then((res: any) => {
      if (res?.status === 200) {
        setHardSkills(res.data);
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    handleSoftSearch('Soft');
  }, [debouncedSearchSoftTerm])

  useEffect(() => {
    handleHardSearch('Hard');
  }, [debouncedSearchHardTerm])

  function handleSubmit(event: any) {
    event.preventDefault()
    editSkills(skills, user?.id)
      .then((res: any) => {
        if (res?.status === 200) {
          onClose();
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function addSkill(addedSkill: any) {
    setSkills([...skills, addedSkill]);
  } 

  function removeSkill(removedSkill: any) {
    const updatedSkills = skills.filter((skill: any) => {
      return skill?.id !== removedSkill?.id;
    })
    setSkills(updatedSkills);
  }
  
  return (
    <Modal size={'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
          <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}>Редактирование навыков</h3>
            </ModalHeader>
            <ModalCloseButton top={'14px'} />
            <ModalBody
              padding='20px'
              display='flex'
              flexDirection='column'
              gap='30px'
              borderTop='1px solid var(--addable-gray)'
              borderBottom='1px solid var(--addable-gray)'
            >
                <h5>Hard Skills</h5>
                <div defaultValue={'Введите значение'}>
                  <InputGroup padding={'6px'}>
                    <InputLeftElement pointerEvents='none'>
                      <SearchIcon
                        color='var(--addable-gray)'
                        width={18}
                        height={18}
                      />
                    </InputLeftElement>
                    <Input
                      value={searchHardTerm}
                      onChange={e => setSearchHardTerm(e.target.value)}
                      color='messenger'
                      height={'28px'}
                      variant='flushed'
                      placeholder='Введите значение'
                    />
                  </InputGroup>
                  {searchHardTerm &&
                  <SkillList>
                    {hardSkills.map((skill: any) =>
                      <Skill title={skill?.name} key={skill?.id}>
                        <SkillButton type='button' onClick={() => addSkill(skill)}>+</SkillButton>
                      </Skill>
                    )}
                  </SkillList>
                  }
                </div>
              <div>
                <h5>Soft skills</h5>
                <InputGroup padding={'6px'}>
                  <InputLeftElement pointerEvents='none'>
                    <SearchIcon
                      color='var(--addable-gray)'
                      width={18}
                      height={18}
                    />
                  </InputLeftElement>
                  <Input
                    value={searchSoftTerm}
                    onChange={e => setSearchSoftTerm(e.target.value)}
                    color='messenger'
                    height={'28px'}
                    variant='flushed'
                    placeholder='Введите значение'
                  />
                </InputGroup>
                {searchSoftTerm &&
                  <SkillList>
                    {softSkills && softSkills.map((skill: any) =>
                      <Skill title={skill?.name} key={skill?.id}>
                        <SkillButton type='button' onClick={() => addSkill(skill)}>+</SkillButton>
                      </Skill>
                    )}
                  </SkillList>
                }
              </div>
              <SkillList>
                {skills && skills.map((skill: any) => 
                  <Skill title={skill.name} key={skill.id}>
                    <SkillButton type='button' onClick={() => removeSkill(skill)}>-</SkillButton>
                  </Skill>
                )}
              </SkillList>
            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
              <Button
                type='button'
                width='100%'
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                type='submit'
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
              >
                Сохранить изменения
              </Button>
            </ModalFooter>
          </ModalContent>
      </form>
    </Modal>
  )
}
