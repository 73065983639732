import styled from "styled-components";

export const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const H6 = styled.h6`
    color: var(--main-purple);
`;

export const Skills = styled.div`
    display: flex;
    gap: 8px;
    flex-wrap: wrap; 
`;
