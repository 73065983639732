import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px; 

  min-width: 816px;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(fit-content + 36px);
  border-radius: 16px;
  padding: 18px;
  background: var(--main-lightgray);
`;

export const DepartmentLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Groups = styled.div`
  display: flex;
`;

export const GroupsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  min-width: 735px;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const H6 = styled.h6`
    color: var(--main-purple);
`;

