import axios from 'axios';
import type { IUser } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));
const userInitial = JSON.parse(localStorage.getItem('user'));

export const editProfile = async (user: IUser) => {
    const updatedData = {
        id: userInitial.id,
        email: user?.email || userInitial.email,
        first_name: user?.first_name || userInitial.first_name,
        last_name: user?.last_name || userInitial.first_name,
        patronymic: user?.patronymic || userInitial.patronymic,
        company: user?.company || userInitial.company,
    }

    const res = await axios({
        url: `${API}/users/${userInitial.id}/`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: updatedData
    })
    
    return res
} 