import React from 'react';
import { Container, Text } from './styles';

export interface ISkillProps {
    title: string;
    children?: React.ReactNode;
}

export const Skill: React.FC<ISkillProps> = ({ title, children }) => {
  return (
    <Container>
        <Text>{title}</Text>
        <>{children}</>
    </Container>
  )
}
