import React, { useState, useEffect } from 'react';
import { Card } from '@/shared';
import {
    Head,
    H6,
    Skills,
} from './styles';
import { Filters } from '@/features/common';
import EditIcon from '@/assets/icons/edit.svg';
import { Button, useDisclosure } from '@chakra-ui/react';
import { Skill } from '@/shared';
import { EditSkillsPopup } from '@/features/employee';
import { getSkills } from '@/features/employee';


export const MySkills: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [skillsType, setSkillsType] = useState('Hard');
  const [skills, setSkills] = useState([]);
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    getSkills(user?.id)
      .then((res: any) => {
        if (res?.status === 200) {
          setSkills(res.data.skill);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])
 
   const options = [
    {
        id: 1,
        name: 'Hard Skills',
        onClick: () => {
            setSkillsType('Hard');
        }
    },
    {
        id: 2,
        name: 'Soft Skills',
        onClick: () => {
            setSkillsType('Soft');
        }
    },
  ];

  return (
    <>
        <Card>
            <Head>
                <h3>Мои навыки</h3>
                <Button
                    onClick={onOpen}
                    border='1px var(--addable-gray) solid'
                    borderRadius='8px'
                    bg='var(--main-white)'
                    leftIcon={<EditIcon color='var(--main-purple)' />} 
                >
                    <H6>Редактировать</H6>
                </Button>
            </Head>
            <Filters options={options}/>
            <Skills>
                {skills && skills.map((skill: any) =>
                    <div key={skill.id}>
                        {skill.type === skillsType &&
                            <Skill title={skill.name} key={skill.id}/>
                        }
                    </div>
                )}
            </Skills>
        </Card>
        <EditSkillsPopup isOpen={isOpen} onClose={onClose}/>
    </>
  )
}
