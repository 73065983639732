import React from 'react';
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { 
  getDepartments, 
  createGroup,
} from '../../api';
import departmentsState from '../../store/departmentsState';
import { observer } from 'mobx-react-lite';
import { getCompany } from '@/entities/company/api';

interface IFormFileds {
  name: string;
}

export interface CreateGroupPopupProps {
  isOpen: boolean;
  onClose: () => void;  
  department: any;
}

export const CreateGroupPopup: React.FC<CreateGroupPopupProps> = observer(({ department, isOpen, onClose}) => {
  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

  function handleGetDepartments() {
    getCompany(companyId)
      .then((res: any) => {
        departmentsState.setDepartments(res.data.group);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    createGroup(data, department.id)
      .then((res: any) => {
        if (res?.status === 201) {
          handleGetDepartments();
          onClose();
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  return (
    <Modal size={'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}>Новый отдел</h3>
            </ModalHeader>
            <ModalCloseButton top={'14px'} />
            <ModalBody
              padding='20px'
              display='flex'
              flexDirection='column'
              gap='30px'
              borderTop='1px solid var(--addable-gray)'
              borderBottom='1px solid var(--addable-gray)'
            >
              <h5>Добавьте отдел в департамент {department.name}</h5>
              <FormControl isInvalid={Boolean(errors.name)}>
                <FormLabel>Название отдела *</FormLabel>
                <Input
                  id='name'
                  {...register('name', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='Разработка ПО'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.name && <>{errors.name.message}</>}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
              <Button
                type='button'
                width='100%'
                onClick={onClose}
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                type='submit'
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
                isLoading={isSubmitting ? true : false}
              >
                Создать отдел
              </Button>
            </ModalFooter>
          </ModalContent>
      </form>
    </Modal>
  )
});
