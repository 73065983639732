import { makeAutoObservable } from "mobx";

class CoursesState {
  courses: any[] = [];
  coursesFilter = 'global';

  constructor() {
    makeAutoObservable(this);
  }

  setCourses(courses: any[]) {
    this.courses = courses;
  }

  setFilter(newFilter: string) {
    this.coursesFilter = newFilter;
  }
}

export default new CoursesState();
