import React, { useEffect, useState } from 'react';
import { Card } from '@/shared';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { 
  Top,
  Left,
  Bottom,
  Right,
  H6,
} from './styles';
import { EmployeesList } from '../EmployeesList/EmployeesList';
import { getCompany } from '@/entities/company/api';
import { ICompany } from '@/shared';
import { Button, useDisclosure } from '@chakra-ui/react';
import EditIcon from '@/assets/icons/edit.svg';
import { deleteDepartment } from '@/features/department';
import { getDepartments } from '@/features/department';
import departmentsState from '@/features/department';
import { EditGroupPopup } from '@/features/department';
import companyState from '@/entities/company/store/companyState';
import { observer } from 'mobx-react-lite';

export interface GroupProps {
  group: any;
}

export const Group: React.FC<GroupProps> = observer(({ group }) => {
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

  useEffect(() => {
    getCompany(companyId)
      .then((res: any) => {
        if (res?.status === 200) {
          companyState.setCompany(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  function handleGetDepartments() {
    getCompany(companyId)
      .then((res: any) => {
        departmentsState.setDepartments(res.data.group);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function handleDelete() {
    deleteDepartment(group.id)
      .then((res: any) => {
        if (res?.status === 204) {
          handleGetDepartments();
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  return (
    <>
      <Card>
        <Top>
          <Left>
            <h5>{group.name}</h5>
            <p>{group.users.length} сотрудник(а/ов)</p>
          </Left>
          <Right>
            <Button
              onClick={handleDelete}
              border='1px var(--addable-gray) solid'
              borderRadius='8px'
              bg='var(--main-white)'
              leftIcon={<EditIcon color='var(--main-purple)' />} 
            >
              <H6>Удалить</H6>
            </Button>
            <Button
              onClick={onEditOpen}
              border='1px var(--addable-gray) solid'
              borderRadius='8px'
              bg='var(--main-white)'
              leftIcon={<EditIcon color='var(--main-purple)' />} 
            >
                  <H6>Редактировать</H6>
            </Button>
            <button
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <CollapseIcon
                style={{
                  transform: isCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
                }}
              />
            </button>
          </Right>
        </Top>
        {!isCollapsed && group.users.length > 0 &&
          <Bottom>
            <EmployeesList users={group.users} company={companyState.company}/>
          </Bottom>
        }
      </Card>
      <EditGroupPopup isOpen={isEditOpen} onClose={onEditClose} group={group}/>
    </>
  )
});
