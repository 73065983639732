import React, { useMemo, useState } from 'react';
import {
    Container,
    Absolute,
    Content,
    Top,
    Description,
    Text,
    CollapseButton,
    SkillsWrapper,
    SkillsTitle,
} from './styles';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { SkillsList } from '@/widgets';
import { Card } from '@/shared';

export interface StepCardProps {
    step: any;
}

export const StepCard: React.FC<StepCardProps> = ({ step }) => {
    const [isCardCollapsed, setIsCardCollapsed] = useState(true);
    const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);

    const [hardSkills, setHardSkills] = useState([]);
    const [softSkills, setSoftSkills] = useState([]);

    useMemo(() => {
        const hardSkillFiltered = step?.skill?.filter((skill: any) => {
            return skill.type === 'Hard'
        })

        setHardSkills(hardSkillFiltered);

        const softSkillFiltered = step?.skill?.filter((skill: any) => {
            return skill.type === 'Soft'
        })

        setSoftSkills(softSkillFiltered);

        const hardSkillsFiltered = step?.skills?.filter((skill: any) => {
            return skill.type === 'Hard'
        })

        setHardSkills(hardSkillsFiltered);

        const softSkillsFiltered = step?.skills?.filter((skill: any) => {
            return skill.type === 'Soft'
        })

        setSoftSkills(softSkillsFiltered);
    }, [step])

  return (
    <Container>
        <Absolute>
            <Card>
                <Content>
                    <Top>
                        <h5>{step.title}</h5>
                        <button onClick={() => setIsCardCollapsed(!isCardCollapsed)}>
                            <CollapseIcon
                                style={{
                                    transform: isCardCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
                                }}
                                width={20}
                                height={20}
                            />
                        </button>
                    </Top>
                    {!isCardCollapsed &&
                        <Content>
                            <Description
                                style={{
                                    maxHeight: isDescriptionCollapsed ? '64px' : 'fit-content'
                                }}
                            >
                                <Text>{step.description}</Text>
                            </Description>  
                            {step.description &&
                                <CollapseButton
                                    onClick={() => setIsDescriptionCollapsed(!isDescriptionCollapsed)}
                                >
                                    Показать {isDescriptionCollapsed ? <>больше</> : <>меньше</>}
                                    <CollapseIcon
                                        style={{
                                            transform: isDescriptionCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
                                        }}
                                        width={10}
                                        height={5}
                                    />
                                </CollapseButton>
                            }
                            <SkillsWrapper>
                                <SkillsTitle>Hard Skills</SkillsTitle>
                                <SkillsList skills={hardSkills}/>
                            </SkillsWrapper>
                            <SkillsWrapper>
                                <SkillsTitle>SoftSkills</SkillsTitle>
                                <SkillsList skills={step?.softSkills}/>
                            </SkillsWrapper>
                        </Content>
                    }
                </Content>
            </Card>
        </Absolute>
    </Container>
  )
};