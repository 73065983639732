import React from 'react';
import { 
  Container,
  User,
  Image,
  Info,
  P2,
  Categories,
  Category,
  CategoryTitle,
  LinkWrapper,
  LogoutButton,
} from './styles';
import defaultAvatar from '@/assets/images/avatar.jpg';
import Bell from '@/assets/icons/bell.svg';
import Logout from '@/assets/icons/logout.svg';
import { EditProfilePopup } from '@/entities/user/ui/EditProfilePopup/EditProfilePopup';
import { useDisclosure } from '@chakra-ui/react';
import { logout } from '@/entities/user/api';
import { NavLink } from 'react-router-dom';

export type TLink = {
  id: number;
  title: string;
  to: string;
}

export type TCategories = {
  id: number; 
  title?: string;
  links: TLink[];
}

export interface INavbar {
  categories: TCategories[];
}

export const Navbar: React.FC<INavbar> = ({ categories }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = JSON.parse(localStorage.getItem('user'));

  function handleLogout() {
    logout()
      .then((res: any) => {
        if (res?.status === 205) {
          
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        location.reload();
      })
  }

  return (
    <>
      <Container>
        <User
          onClick={onOpen}
        >
          {user?.image ?
            <Image src={user.image} alt='avatar'/>
            :
            <Image src={defaultAvatar} alt='avatar'/>
          }
          <Info>
            <h6>{user?.first_name} {'  '} {user?.last_name}</h6>
            <P2>{user?.role}</P2>
          </Info>
          <Bell
            width={24}
            height={24}
          />
        </User>
        <Categories>
          {categories.map(category => 
            <Category key={category.id}>
              {category?.title &&
                <CategoryTitle>{category.title}</CategoryTitle>
              }
              {category.links.map(link => 
                <NavLink
                  to={link.to}
                  key={link.id}
                >
                  <LinkWrapper>
                    <h6>{link.title}</h6>
                  </LinkWrapper>
                </NavLink>
              )}
            </Category>
          )}
        </Categories>
        <LogoutButton
          onClick={handleLogout}
        >
          <Logout
            color='#466DF2'
            width={15}
            height={15}
          />
          <h6 style={{ color: 'var(--main-purple)' }}>Выход</h6>
        </LogoutButton>
      </Container>
      <EditProfilePopup user={user} isOpen={isOpen} onClose={onClose}/>
    </>
  )
}
