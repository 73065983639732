import React from 'react';
import { VacanciesTopBar } from '@/widgets';
import { VacanciesList } from '@/widgets';
import { ListWrapper } from './styles';
import { Filters } from '@/features/common';
import vacanciesState from '@/entities/vacanci/store/vacanciesState';

export const Vacancies: React.FC = () => {
  const options = [
    {
      id: 1,
      name: 'Глобальная база должностей',
      onClick: () => vacanciesState.setFilter('global'),
    },
    {
      id: 2,
      name: 'Должности компании',
      onClick: () => vacanciesState.setFilter('company'),
    },
  ];

  return (
    <>
      <VacanciesTopBar/>
      <Filters options={options}/>
      <ListWrapper>
        <VacanciesList/>
      </ListWrapper>
    </>
  )
}
