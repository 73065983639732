import React from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
} from '@chakra-ui/react';
import { deleteEmployee } from '@/features/employee/api';
import { getEmployees } from '@/features/employee/api';
import employeesState from '@/pages/Employees/store/employeesState';

export interface DeleteUserPopupProps {
    isOpen: boolean;
    onClose: () => void;
    userId: number;
}

export const DeleteUserPopup: React.FC<DeleteUserPopupProps> = ({ isOpen, onClose, userId }) => {
    const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

    function handleGetEmployees() {
        getEmployees(companyId)
            .then((res: any) => {
                if (res?.status === 200) {
                    employeesState.setEmployees(res.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    function handleDelete() {
        deleteEmployee(userId)
          .then((res: any) => {
            if (res?.status === 204) {
              handleGetEmployees();
              onClose();
            }
          })
          .catch((error) => {
            console.log(error);
          })
    }

  return (
    <Modal size={'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form>
        <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
            <h3 style={{ textAlign: 'center' }}>Удаление сотрудника</h3>
            </ModalHeader>
            <ModalBody
                padding='20px'
                display='flex'
                flexDirection='column'
                gap='30px'
                borderTop='1px solid var(--addable-gray)'
                borderBottom='1px solid var(--addable-gray)'
            >
                Отменить действие после подтверждения будет невозможно. Вы действительно хотите удалить сотрудника?
            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
                <Button
                    type='button'
                    width='100%'
                    onClick={onClose}
                    variant='outline'
                    color='var(--main-purple)'
                    borderRadius='8px'
                >
                    Отмена
                </Button>
                <Button
                    onClick={handleDelete}
                    type='button'
                    width='100%'
                    borderRadius='8px'
                    bg='var(--main-purple)'
                    _hover={{ bg: 'var(--hover-purple)' }}
                    color='#fff'
                >
                    Удалить
                </Button>
            </ModalFooter>
        </ModalContent>
        </form>
    </Modal>
  )
}
