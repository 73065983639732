import React, { useState } from 'react';
import { CareerSteps } from '@/widgets';
import { Filters } from '@/features/common';
import { Page, CareerWidgetWrapper } from './styles';
import { CareerWidget } from '@/widgets';
import { CareerSkills } from '@/widgets';

export const Career: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('road');

  const options = [
    {
      id: 1,
      name: 'Карьерный путь',
      onClick: () => setActiveTab('road'),
    },
    {
      id: 2,
      name: 'Список навыков',
      onClick: () => setActiveTab('skills'),
    },
  ];

  return (
    <Page>
      <Filters options={options}/>
      {activeTab === 'road' &&
        <CareerSteps/>
      }
      {activeTab === 'skills' &&
        <CareerSkills/>
      }
      <CareerWidgetWrapper>
        <CareerWidget/>
      </CareerWidgetWrapper>
    </Page>
  )
}
