import styled from "styled-components";

export const Board = styled.div`
    width: 100%;
    padding: 40px;
    padding-left: 12px;
    padding-bottom: 0;
`;

export const TopBar = styled.div`
    display: flex;
    align-items: start;
    gap: 30px;
    width: 100%;
    padding-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
    width: 167px;
`;
