import React, { useState, useEffect } from 'react';
import {
  Title,
  Content,
  Scheme,
  Departments,
  Wrapper,
  Divider,
  Vertical,
} from './styles';
import {
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import AddIcon from '@/assets/icons/add.svg';
import { Department } from '@/widgets';
import { CreateDepartmentPopup } from '@/features/department';
import departmentsState from '@/features/department';
import { observer } from 'mobx-react-lite';
import companyState from '@/entities/company/store/companyState';
import { getCompany } from '@/entities/company/api';
import { ICompany } from '@/shared';

export const Structure: React.FC = observer(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [company, setCompany] = useState<ICompany>();

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

  useEffect(() => {
    getCompany(companyId)
      .then((res) => {
        companyState.setCompany(res.data);
        setCompany(res.data);
        departmentsState.setDepartments(res.data.group);
      })
      .catch((e) => {
        console.log(e);
      })
  }, [])

  return (
    <>
      <Title>{company?.name}</Title>  
      <Content>
        <Vertical/>
        <Scheme>
          <Departments>
            <div style={{ display: 'flex' }}>
              <Divider/>
              <div style={{ minWidth: '816px', marginTop: '12px' }}>
                <Button
                  width='100%'
                  borderRadius='8px'
                  bg='var(--main-purple)'
                  _hover={{ bg: 'var(--hover-purple)' }}
                  leftIcon={<AddIcon color='#fff' />} 
                  onClick={onOpen}
                >
                  <h6 style={{ color: '#fff' }}>Добавить департамент</h6>
                </Button>
              </div>
            </div>
            {departmentsState.departments && departmentsState.departments.map(department =>
              <Wrapper key={department.id}>
                <Divider/>
                <Department department={department} key={department.id} />
              </Wrapper>
            )}
          </Departments>
        </Scheme>
      </Content>
      <CreateDepartmentPopup isOpen={isOpen} onClose={onClose} />
    </>
  )
});
