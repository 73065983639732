import React, { useEffect } from 'react';
import { Top, Inputs, Bottom } from '@/app/layouts/Auth/styles';
import { ButtonSolid } from '@/UI';
import { Link } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
} from '@chakra-ui/react';
import { login } from '@/entities/user';
import { useNavigate } from 'react-router-dom';

interface IFormFileds {
  email: string;
  password: string;
}

export const SignIn: React.FC = () => { 
  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    login(data)
    .then((res: any) => {
      if (res?.status === 200) {
        localStorage.setItem('user', JSON.stringify(res.data.user));
        localStorage.setItem('accessToken', JSON.stringify(res.data.access));
        localStorage.setItem('refreshToken', JSON.stringify(res.data.refresh));
        location.reload();
        navigate('/dashboard-admin-companies');
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user?.role === 'Admin') {
      navigate('/dashboard-admin-companies');
    }

    if (user?.role === 'HR manager') {
      navigate('/dashboard-hr-employees');
    }

    if (user?.role === 'User') {
      navigate('/dashboard-employee-profile');
    }
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Top><h3>Вход</h3></Top>
      <Inputs>
        <FormControl isInvalid={Boolean(errors.email)}>
          <FormLabel>E-mail *</FormLabel>
          <Input
            id='email'
            {...register('email', { 
              required: 'Обязательное поле'
            })}
            placeholder='example.email@gmail.com'
            type='email'
            size='sm'
            variant='flushed'
          />
          <FormErrorMessage>{errors.email && <>{errors.email.message}</>}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.password)}>
          <FormLabel>Пароль *</FormLabel>
          <Input
            id='password'
            {...register('password', { 
              required: 'Обязательное поле'
            })}
            placeholder='••••••••'
            type='password'
            size='sm'
            variant='flushed'
          />
          <FormErrorMessage>{errors.password && <>{errors.password.message}</>}</FormErrorMessage>
        </FormControl>
        <Link to="/RecoveryPassword">Не помню пароль</Link>
      </Inputs>
      <Bottom>
        <ButtonSolid
          type='submit'
          disabled={isSubmitting ? true : false}
        >
          Войти
        </ButtonSolid>
      </Bottom>
    </form>
  )
}
