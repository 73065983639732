import React, { useEffect, useState } from 'react';
import { StepCard } from '@/entities/step';
import { Container } from './styles';
import { getSteps } from '@/features/career';
import careerState from '@/features/career/store/careerState';
import { observer } from 'mobx-react-lite';
import { getVacancyById } from '@/entities/vacanci';

export const CareerSteps: React.FC = observer(() => {
  const [isAwaiting, setIsAwaiting] = useState(false);
  const vacancyId = JSON.parse(localStorage.getItem('user'))?.vacancy[0];

  function handleGetSteps() {
    getSteps()
      .then((data: any) => {
        data.data[0].forEach((newStep: any) => {
          let flag = true;
          careerState.steps.forEach((step: any) => {
            if (step.grade === newStep.grade) {
              flag = false
            }
          })

          if (flag) {
            careerState.setSteps([...careerState.steps, newStep]);
          }
        });
        careerState.setSkills(data.data[1]);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsAwaiting(false);
      })
  }

  useEffect(() => {
    if (careerState.steps.length === 0) {
      setIsAwaiting(true);
      getVacancyById(vacancyId)
      .then((res) => {
        careerState.setSteps([res.data]);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        handleGetSteps();
      })
    }
  }, [])

  return (
    <Container>
      {isAwaiting && <>Loading...</>}
      {!isAwaiting && careerState.steps && careerState.steps.map((step: { id: React.Key; }, index: number) => 
        <div
          style={{ 
            width: '296px',
            marginTop: `${(careerState.steps.length - index - 1) * 74}px`,
          }}
          key={index}
        >
          <StepCard step={step}/>
        </div>
      )}
    </Container>
  )
});
