import axios from 'axios';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const getDepartments = async (companyId: number) => {
  const res = await axios<any[]>({
      url: `${API}/groups/?level=0&parent=${companyId}`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
} 

export const getGroups = async (parent: number) => {
    const res = await axios<any[]>({
        url: `${API}/groups/?level=1&parent=${parent}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
  } 

export const getDepartmentByName = async (name: string) => {
    const res = await axios<any[]>({
        url: `${API}/groups/?level=0&name=${name}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const getGroupByName = async (name: string) => {
    const res = await axios<any[]>({
        url: `${API}/groups/?level=1&name=${name}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const getGroupById = async (id: number) => {
    const res = await axios<any>({
        url: `${API}/groups/${id}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const createDepartment = async (dapartment: any) => {
    const newDepartment = {
        name: dapartment.name,
        users: [] as any[],
    }

    const res = await axios<any>({
        url: `${API}/groups/?level=0`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: newDepartment,
    })
    
    return res
}

export const editDepartment = async (department: any, id: number) => {
    const newDepartment = {
        name: department.name,
    }

    const res = await axios<any>({
        url: `${API}/groups/${id}/`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: newDepartment,
    })
    
    return res
}

export const deleteDepartment = async (id: number) => {
    const res = await axios<any>({
        url: `${API}/groups/${id}/`,
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const createGroup = async (group: any, parentId: number) => {
    const newGroup = {
        name: group.name,
        users: [] as any[],
        parent: parentId,
    }

    const res = await axios<any>({
        url: `${API}/groups/?level=1`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: newGroup,
    })
    
    return res
}

export const connectDepartmantWithCompany = async (departmentId: number, companyId: number) => {
    const res = await axios<any>({
        url: `${API}/group-company/`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: {
            group: departmentId,
            company: companyId,
        },
    })
    
    return res
}
