import React, { useState, useEffect } from 'react';
import { Container, Inputs } from './styles';
import {
  InputGroup,
  InputLeftElement,
  Input,
  Button 
} from '@chakra-ui/react';
import AddIcon from '@/assets/icons/add.svg';
import SearchIcon from '@/assets/icons/search.svg';
import { useDebounce } from '@/shared';
import { searchVacanci } from '@/features/vacanci';
import { observer } from 'mobx-react-lite';
import vacanciesState from '@/entities/vacanci/store/vacanciesState';

export const VacanciesTopBar: React.FC = observer(() => {
  const [searchTerm, setSearchTerm] = useState('');
  
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  function handleSearch() {
    searchVacanci(debouncedSearchTerm)
    .then((res: any) => {
      if (res?.status === 200) {
        vacanciesState.setVacancies(res.data.results);
      }
    })
    .catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (typeof debouncedSearchTerm === 'string') {
      handleSearch();
    }
  }, [debouncedSearchTerm]);

  return (
    <Container>
      <Inputs>
        <InputGroup padding={'6px'}>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon
              color='var(--addable-gray)'
              width={18}
              height={18}
            />
          </InputLeftElement>
          <Input
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            color='messenger'
            height={'28px'}
            variant='flushed'
            placeholder='Поиск вакансии по названию или навыкам'
          />
        </InputGroup>
        {/* <Button
          width={'400px'}
          borderRadius='8px'
          bg='var(--main-purple)'
          _hover={{ bg: 'var(--hover-purple)' }}
          leftIcon={<AddIcon color='#fff' />} 
        >
          <h6 style={{ color: '#fff' }}>Добавить вакансию</h6>
        </Button> */}
      </Inputs>
    </Container>
  )
});
