import styled from 'styled-components';

export const Page = styled.div`
  position: relative;
`;

export const Left = styled.div`
  max-width: calc(100% - 296px - 16px);
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
