import React, { useEffect, useRef, useState } from 'react';
import { Card } from '@/shared';
import { VacanciCard } from '@/entities/vacanci';
import { Container } from './styles';
import { EmptyResponse } from '../EmptyResponse/EmptyResponse';
import { getVacancies } from '@/entities/vacanci';
import vacanciesState from '@/entities/vacanci/store/vacanciesState';
import { observer } from 'mobx-react-lite';
import { getCompany } from '@/entities/company/api';

export const VacanciesList: React.FC = observer(() => {
  const [companyVacancies, setCompanyVacancies] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isAwaiting, setIsAwaiting] = useState(false);
  const lastElement = useRef();
  const observer = useRef<IntersectionObserver>();  

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

  useEffect(() => {
    setIsAwaiting(true);
    getCompany(companyId)
      .then((res) => {
        setCompanyVacancies(res.data.company_positions);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsAwaiting(false);
      })
  }, [])

  function handleGetVacancies() {
    getVacancies(offset)
      .then((res) => {
        vacanciesState.setVacancies([...vacanciesState.vacancies, ...res.data.results]);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsAwaiting(false)
      })
  }

  useEffect(() => {
    setIsAwaiting(true);
    handleGetVacancies();
  }, [offset])

  useEffect(() => {
    if (vacanciesState.vacanciesFilter === 'global') {
      if (isAwaiting) return;
      if (observer.current) observer.current.disconnect();
      const callback = function (entries: any) {
        if (entries[0].isIntersecting) {
          setOffset(offset + 10);
        }
      }
      observer.current = new IntersectionObserver(callback);
      observer.current.observe(lastElement.current)
    }
  }, [isAwaiting, vacanciesState.vacanciesFilter])

  return (
    <>
      {vacanciesState.vacanciesFilter === 'global' &&
        <>
          <Container>
            {vacanciesState.vacancies && vacanciesState.vacancies.map(vacanci =>
              <Card key={vacanci.id}>
                <VacanciCard vacanci={vacanci} key={vacanci.id}/>
              </Card>
            )}
          </Container>
          <div ref={lastElement}></div>
        </>
      }
      {vacanciesState.vacanciesFilter === 'company' &&
        <>
          <Container>
            {companyVacancies && companyVacancies.map(vacanci =>
              <Card key={vacanci.id}>
                <VacanciCard vacanci={vacanci} key={vacanci.id}/>
              </Card>
            )}
          </Container>
        </>
      }
    </>
  )
});
