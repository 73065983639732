import React from 'react';
import { Card } from '@/shared';
import { Top, Title, H6, PlacesList } from './styles';
import EditIcon from '@/assets/icons/edit.svg';
import { Button } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { EditPlacePopup } from './EditPopup/EditPlacePopup';

export const Education: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Card>
      <Top>
        <Title>Образование</Title>
        <Button
          onClick={onOpen}
          border='1px var(--addable-gray) solid'
          borderRadius='8px'
          bg='var(--main-white)'
          leftIcon={<EditIcon color='var(--main-purple)' />} 
        >
          <H6>Редактировать</H6>
        </Button>
      </Top>
      <PlacesList>

      </PlacesList>
      <EditPlacePopup isOpen={isOpen} onClose={onClose}/>
    </Card>
  )
}
