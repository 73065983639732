import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  height: calc(100vh - 60px - 28px - 16px);
  overflow: hidden;
  overflow: auto; 
`;
