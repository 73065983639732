import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px; 
`;

export const Label = styled.label`
`;

export const Required = styled.span`
    opacity: 1;
    color: var(--addable-red);
`;

export const InputStyles = styled.input`
    padding-bottom: 4px;
    border-bottom: 1px var(--addable-gray, #E6E6E6) solid;

    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    &::placeholder {
        opacity: .2;
    }
`;

export const Error = styled.p`
    font-size: 10px;
    line-height: 12px;
    color: var(--addable-red);
`;
