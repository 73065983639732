import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Navbar } from '@/widgets';
import { Page } from './styles';
import { useNavigate } from 'react-router-dom';

const categories = [
    {
        id: 1,
        links: [
            {
                id: 1,
                title: 'Компании',
                to: '/dashboard-admin-companies',
            },
            {
                id: 1,
                title: 'Настройки',
                to: '/dashboard-admin-settings',
            },
        ],
    },
];

export const AdminLayout: React.FC = () => {
    const navigate = useNavigate();

    const user = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        if (!user) {
          navigate('/');
        }
    
        if (user?.role !== 'Admin') {
          navigate('/');
        }
    }, [])

  return (
    <Page>
        <Navbar categories={categories} />
        <Outlet />
    </Page>
  )
}
