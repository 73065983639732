import React from 'react';
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IUser } from '@/shared';
import { editProfile } from '@/features/user';

interface IFormFileds {
    first_name: string;
    last_name: string;
    patronymic: string;
    birthday: string;
    email: string;
    company: string;
    department: string;
    role: string;
    isSuperHR: boolean;
}

export interface EditProfilePopupProps {
  isOpen: boolean;
  onClose: () => void;
  user: IUser;
}

export const EditProfilePopup: React.FC<EditProfilePopupProps> = ({ isOpen, onClose, user }) => {
  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    editProfile(data)
      .then((res: any) => {
        if (res?.status === 200) {
          localStorage.setItem('user', JSON.stringify(res.data));
          onClose();
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
  }

  return (
    <Modal size={'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}> Редактирование профиля</h3>
            </ModalHeader>
            <ModalCloseButton top={'14px'} />
            <ModalBody
              padding='20px'
              display='flex'
              flexDirection='column'
              gap='30px'
              borderTop='1px solid var(--addable-gray)'
              borderBottom='1px solid var(--addable-gray)'
            >
              <h5>Основная информация</h5>
              <FormControl isInvalid={Boolean(errors.first_name)}>
                <FormLabel>Имя</FormLabel>
                <Input
                  id='first_name'
                  {...register('first_name')}
                  placeholder='Мария'
                  defaultValue={user?.first_name}
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.first_name && <>{errors.first_name.message}</>}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.first_name)}>
                <FormLabel>Фамилия</FormLabel>
                <Input
                  id='last_name'
                  {...register('last_name')}
                  placeholder='Васенева'
                  defaultValue={user?.last_name}
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.last_name && <>{errors.last_name.message}</>}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.patronymic)}>
                <FormLabel>Отчество</FormLabel>
                <Input
                  id='patronymic'
                  {...register('patronymic')}
                  placeholder='Евгеньевна'
                  defaultValue={user?.patronymic}
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.patronymic && <>{errors.patronymic.message}</>}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.email)}>
                <FormLabel>Почта</FormLabel>
                <Input
                  id='email'
                  {...register('email')}
                  placeholder='vaseneva@emp.ru'
                  defaultValue={user?.email}
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.email && <>{errors.email.message}</>}</FormErrorMessage>
              </FormControl>

              <h5>Работа и должность</h5>
              <FormControl isInvalid={Boolean(errors.company)}>
                <FormLabel>Компания</FormLabel>
                <Input
                  disabled
                  id='company'
                  {...register('company')}
                  placeholder='Эмплифлоу'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.company && <>{errors.company.message}</>}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={Boolean(errors.role)}>
                <FormLabel>Должность</FormLabel>
                <Input
                  disabled
                  defaultValue={user?.role}
                  id='role'
                  {...register('role')}
                  placeholder='Hr-специалист'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.role && <>{errors.role.message}</>}</FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
              <Button
                type='button'
                width='100%'
                onClick={onClose}
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                type='submit'
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
                isLoading={isSubmitting ? true : false}
              >
                Сохранить изменения
              </Button>
            </ModalFooter>
          </ModalContent>
      </form>
    </Modal>
  )
}