import React from 'react';
import {
    Container,
    Content,
    Text,
    Title
} from './styles';

export const EmptyResponse: React.FC = () => {
  return (
    <Container>
        <Content>
            <Title>Нет результатов</Title>
            <Text>Попробуйте настроить поиск по-другому</Text>
        </Content>
    </Container>
  )
}
