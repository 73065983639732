import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 32px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px; 
`;

export const CourseButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  padding: 0 8px;
  border-radius: 8px;
  background: var(--main-white);
  color: var(--main-purple);
  border: 1px solid var(--addable-gray);
  font-weight: 600;
`;

export const SearchButton = styled.button`
  width: max-content;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 32px;
  padding: 0 8px;
  border-radius: 8px;
  background: var(--main-purple);
  color: var(--main-white);
  font-weight: 600;
`;

export const PopoverButton = styled.button`
  display: block;
  font-size: 12px;
  line-height: 28px;
  width: 100%;
  text-align: left;
`;
