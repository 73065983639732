export const companies = [
    {
        id: 1,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 2,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 3,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 4,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 5,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },

    {
        id: 6,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 7,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 8,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 9,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 10,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 11,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 12,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 13,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 14,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 15,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 16,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 17,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 18,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 19,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 20,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 21,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
    {
        id: 22,
        name: 'Компания 5',
        subscription_terms: 'Ежемесячная оплата за 371 сотрудника нв платформе, каждое 10-е число',
        INN: '234 4354 54656',
        OGRN: '234 4354 54656',
        employeesCount: 22,
        city: 'Москва',
    },
];