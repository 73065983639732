import React, { useState } from 'react';
import {
  Container,
  Top,
  Description,
  Text,
  CollapseButton,
  SkillsWrapper,
  SkillsTitle,
} from './styles';
import { SkillsList } from '@/widgets';
import CollapseIcon from '@/assets/icons/collapse.svg';

export interface ICourseCardProps {
  course: any;
}

export const CourseCard: React.FC<ICourseCardProps> = ({ course }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Container>
        <Top>
            <h5>{course.title}</h5>
        </Top>
        <Description
            style={{
                maxHeight: isCollapsed ? '64px' : 'fit-content'
            }}
        >
            <Text>{course.description}</Text>
        </Description>
        {course.description &&
            <CollapseButton
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                Показать {isCollapsed ? <>больше</> : <>меньше</>}
                <CollapseIcon
                    style={{
                        transform: isCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
                    }}
                    width={10}
                    height={5}
                />
            </CollapseButton>
        }
        {/* <SkillsWrapper>
            <SkillsTitle>Навыки</SkillsTitle>
            {course?.soft_skills && <SkillsList skills={course?.skills}/>}
        </SkillsWrapper> */}
        <a href={course.link} target='_blank'>Перейти к курсу</a>
    </Container>
  )
}
