import React from 'react';
import {
  Button 
} from '@chakra-ui/react';

export interface IButtonSolidProps extends Omit<React.ComponentProps<'button'>, 'ref'>  {}

export const ButtonSolid: React.FC<IButtonSolidProps> = ({ children, ...props }) => {
  return (
    <Button
      width='100%'
      borderRadius='8px'
      bg='var(--main-purple)'
      _hover={{ bg: 'var(--hover-purple)' }}
      color='#fff'
      {...props}
    >
      {children}
    </Button>
  )
}
