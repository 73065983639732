import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
`;

export const Title = styled.h3`
    text-align: center;
    font-size: 26px;
    color: var(--disabled);
`;

export const Text = styled.p`
    text-align: center;
    font-weight: 600;
    font-size: 20px; 
    color: var(--disabled);
`;
