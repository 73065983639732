import { makeAutoObservable } from "mobx";
import { IVacanci } from "@/shared";

class VacanciesState {
  vacancies: IVacanci[] = [];
  vacanciesFilter = 'global';

  constructor() {
    makeAutoObservable(this);
  }

  setVacancies(vacancies: IVacanci[]) {
    this.vacancies = vacancies;
  }

  setFilter(newFilter: string) {
    this.vacanciesFilter = newFilter;
  }
}

export default new VacanciesState();
