import styled from 'styled-components';

export const Page = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100vh;
    width: 100vw;
    background-color: var(--main-purple);

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    width: 400px;
    background: var(--main-white);
    border-radius: 16px;
`;

export const Top = styled.div`
    padding: 16px;
    text-align: center;
    border-bottom: 1px solid var(--addable-gray);
`;

export const Text = styled.p`
    font-size: 12px;
`;

export const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 16px;
`;

export const Bottom = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 24px;
    border-top: 1px solid var(--addable-gray);
`;
