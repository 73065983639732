import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: fit-content;
    padding: 16px;
    background: var(--main-lightgray);
    border-radius: 16px;
    border: 1px solid var(--addable-gray);
    height: calc(fit-content + 32px);
`;
