import React from 'react';
import { Outlet } from 'react-router';
import { Page, Content } from './styles';
import image from '../../../assets/auth-bg.png';

export const AuthLayout: React.FC = () => {
  return (
    <Page
        style={{
            backgroundImage: `url(${image})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '90%' 
        }}
    >
      <Content>
        <Outlet />
      </Content>
    </Page>
  )
}
