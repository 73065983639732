import React from 'react';
import careerState from '@/features/career/store/careerState';
import { observer } from 'mobx-react-lite';
import { Skill } from '@/shared';
import { Container, SkillsContainer, List } from './styles';

export const CareerSkills: React.FC = observer(() => {
  return (
    <Container>
      <SkillsContainer>
        <h5>Hard Skills</h5>
        <List>
          {careerState.skills && careerState.skills.map((skill, index) => 
            <>
              {skill.type === 'Hard' &&
                <Skill title={skill.name} key={index}/>
              }
            </>
          )}
        </List>
      </SkillsContainer>

      <SkillsContainer>
      <h5>Soft Skills</h5>
        <List>
          {careerState.skills && careerState.skills.map((skill, index) => 
            <>
              {skill.type === 'Soft' &&
                <Skill title={skill.name} key={index}/>
              }
            </>
          )}
        </List>
      </SkillsContainer>
    </Container>
  )
});
