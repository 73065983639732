import React from 'react';
import { Container, Top, Title, ButtonText, ProgressWrapper, Procent, Info, Left, P } from './styles';
import EditIcon from '@/assets/icons/edit.svg';
import { Button, Progress } from '@chakra-ui/react';

export const CareerWidget: React.FC = () => {
  return (
    <Container>
      <Top>
        <Title>Middle Frontend-разработчик</Title>
        <Button
          border='1px var(--addable-gray) solid'
          borderRadius='8px'
          bg='var(--main-white)'
          leftIcon={<EditIcon color='var(--main-purple)' />} 
        >
          <ButtonText>Редактировать</ButtonText>
        </Button>
      </Top>
      <ProgressWrapper>
        <Procent>23%</Procent>
        <Progress
          colorScheme={'whiteAlpha'}
          background='#6B89F5'
          borderRadius={'100px'}
          value={23}
          height='4px'
          width={'100%'}
        />
      </ProgressWrapper>
      <Info>
        <Left>
          <P>Frontend-разработчик отвечает за разработку  web-приложений в браузерах десктопных и мобильных версиях, следит за соответствием дизайна на макетах и продовой системы...</P>
        </Left>
      </Info>
    </Container>
  )
}
